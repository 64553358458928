@import url('assets/font/stylesheet.css');
* {
  font-family:  "Gilroy","Nunito" ,"Helvetica" ,"sans-serif";
}
:root {
  --main_color: #0071f2;
  --black_color:#111;
  --text_color: #333;
  --gray_color: #666;
  --white: #FEFEFE;
}
.h1title{
  font-weight: 700;
  color:#333;
  margin-bottom: 34px;
}
.css-1pahdxg-control,.css-13cymwt-control{
  box-shadow: none!important;
  border-width:0 0 1px 0!important;
  border-bottom:1px solid #34df7d!important;
  /* flex-direction: row-reverse!important; */
  border-radius: 0!important;
}
.css-1hb7zxy-IndicatorsContainer{
  /* flex-direction: inherit!important; */
}
.css-1s2u09g-control, .css-13cymwt-control{
  border-width:0 0 1px 0!important;
  border-bottom:1px solid #0001!important;
  flex-direction: row-reverse!important;
  border-radius: 0!important;
}
.css-tr4s17-option{
  background-color:#e22424!important;   
}
.css-d7l1ni-option {
  background-color:#e2242433!important;  
}
.css-t3ipsp-control{
 border-color: #e22424!important;
 border-width: 0 0 2px 0!important;
 box-shadow: 0 0 0 0px #e22424!important;
 border-radius: 0!important;
}
.css-1okebmr-indicatorSeparator{
  background-color: none!important;
  width:0!important
}


.banner-animation{
  animation: banner-animation .5s;
}
.banner-animation .phone-animation{
  animation: phone-animation 1s;
}
.banner-animation .banner-an-item{
  animation: banner-an-item 1s;
}
@keyframes banner-animation{
  from{transform:translateY(200px);}
  to{transform:none;}
}
@keyframes phone-animation{
  from{transform:scale(.5);}
  to{transform:translateY(-20px);}
}
@keyframes banner-an-item{
  from{transform:translateX(20px);opacity:0;}
  50%{transform:translateX(20px);opacity:0;}
  to{transform:none;opacity:1}
}
.comment-animation .card{
  animation: comment-animation .6s;
}
@keyframes comment-animation{
  from{transform:scale(1.1);opacity: 0;}
  to{transform:none;opacity: 1;}
}